<template>
<div class="main home">
    <div class="home-box">
        <h1>批次管理</h1>
        <div class="home-content">
            <search-item :searchInfo.sync="info" @searchList="searchList" @addPC="addPC"></search-item>
            <!-- 表格 -->
            <div class="table_container">
                <el-table size="mini" border :data="tableData" stripe v-loading="tableLoading" style="width: 100%" align='center'>
                    <el-table-column prop="batchNo" label="批次编号" align='center'>
                    </el-table-column>
                    <el-table-column prop="startTime" label="起始日期" align='center'>
                    </el-table-column>
                    <el-table-column prop="endTime" label="截止日期" align='center'>
                    </el-table-column>
                    <el-table-column prop="quantity" label="生产数量" align='center'>
                    </el-table-column>
                    <el-table-column prop="tapType" label="是否带阀" align='center'>
                        <template slot-scope="scope">
                            {{scope.row.tapType == 0 ? '无阀' : '有阀'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="caliber" label="口径(mm)" align='center'>
                    </el-table-column>
                    <el-table-column prop="multiplier" label="计量单位" align='center'>
                    </el-table-column>
                    <el-table-column prop="enprNo" label="水司编号" align='center'>
                    </el-table-column>
                    <el-table-column prop="remark" label="说明" align='center'>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="250">
                        <template slot-scope="scope">
                            <el-button @click="handleUpdate(scope.row)" type="text" size="small">修改</el-button>
                            <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
                            <el-button @click="handleSetEnpr(scope.row)" type="text" size="small">设置水司</el-button>
                            <el-button @click="handleSynchronization(scope.row)" type="text" size="small">同步至抄表平台</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination :paginationInfo="paginationInfo" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
            </div>
        </div>
    </div>
    <el-dialog :modal-append-to-body='false' title="新建/修改批次" :visible.sync="showDialog" width="32%">
        <el-form ref="form" label-width="80px">
            <el-form-item label="批次编号" required>
                <el-input clearable style="width:100%;" v-model="pcData.batchNo">
                    <el-button slot="append" type="primary" @click="generatePCNo">重置批次号</el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="日期范围" required>
                <el-date-picker style="width:100%;" v-model="pcData.pcDate" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="生产数量" required>
                <el-input type="number" style="width:100%;" v-model="pcData.quantity"></el-input>
            </el-form-item>
            <el-form-item label="是否带阀" required>
                <el-radio v-model="pcData.tapType" :label="0">无阀</el-radio>
                <el-radio v-model="pcData.tapType" :label="1">有阀</el-radio>
            </el-form-item>
            <el-form-item label="计量单位" required>
                <el-radio-group v-model="pcData.multiplier">
                    <el-radio :label="1">1L</el-radio>
                    <el-radio :label="10">10L</el-radio>
                    <el-radio :label="100">100L</el-radio>
                    <el-radio :label="1000">1000L</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="设备口径" required>
                <el-input style="width:100%;" type="number" v-model="pcData.caliber"></el-input>
            </el-form-item>
            <el-form-item label="水司选择">
                <el-select style="width:100%;" v-model="pcData.enprNo" filterable clearable placeholder="请选择水司">
                    <el-option v-for="item in arrEnpr" :key="item.id" :label="item.enprName + ' - ' + item.enprNo" :value="item.enprNo">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="说明">
                <el-input type="textarea" v-model="pcData.remark"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="pcSubmitLoading" @click="onSubmit">提交</el-button>
                <el-button @click="close">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-dialog :modal-append-to-body='false' title="设置水司" :visible.sync="modifyEnprVisible" width="22%">
        <el-form ref="form" label-width="120px">
            <el-form-item>
                ⚠️该功能只更新该批次下所有的水表所属水司，不会更新批次关联水司
            </el-form-item>
            <el-form-item label="水司选择">
                <el-select v-model="newEnpr" filterable clearable placeholder="请选择水司">
                    <el-option v-for="item in arrEnpr" :key="item.id" :label="item.enprName + ' - ' + item.enprNo" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="enprLoading" @click="updateEnprSumit">提交</el-button>
                <el-button @click="modifyEnprVisible = false">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-dialog :modal-append-to-body='false' title="同步至抄表平台" :visible.sync="synchronizationVisible" width="25%">
        <el-form ref="form" label-width="0">
            <el-form-item>
                ⚠️该功能会将该批次下所有设备同步到抄表平台中，请先检查是否已经设置好相应水司。您即将同步设备到抄表平台，是否立即开始?
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="syncLoading" @click="synchronizationSumit">是</el-button>
                <el-button @click="synchronizationVisible = false">否</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>

<script>
import Pagination from "@/components/pagination";
import SearchItem from "./searchItem";
export default {
    data() {
        return {
            // 表格loading
            tableLoading: true,
            tableData: [], //表格数据
            paginationInfo: {
                pageIndex: 1, // 当前位于哪页
                pageTotal: 0, //表格总页数
                pageSize: 10, //每页显示条数
                pageSizes: [5, 10, 15, 20], //每页显示个数选择器的选项设置
                layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
            },
            pidList: [],
            centerDialogVisible: false,
            registerId: "",
            info: {
                name: ""
            },
            activeName: '0',
            showDialog: false,
            pcData: {
                id: '',
                batchNo: '',
                pcDate: [],
                quantity: '',
                multiplier: 1,
                caliber: '',
                enprNo: '',
                remark: '',
                tapType: 1
            },
            editState: false,
            modifyEnprVisible: false,
            arrEnpr: [],
            synchronizationVisible: false,
            jldw: 3,
            pcSubmitLoading: false,
            newEnpr: '',
            selectedRow: {},
            enprLoading: false,
            closeEnprDialog: false,
            syncLoading: false
        }
    },
    mounted() {
        let that = this
        this.getAllEnpr()
        this.getBatchList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
    },
    methods: {
        //获取所有水司
        getAllEnpr() {
            this.$api.getAllEnpr({}).then(res => {
                if (res.code === 0) {
                    this.arrEnpr = res.data
                }
            })
        },
        //  获取表格信息
        getBatchList(currpage, pageSize, info) {
            console.log(info)
            this.tableLoading = true;
            let params = {
                pageNum: currpage,
                pageSize: pageSize,
                batchNo: info ? info.name : ''
            }
            this.tableData = []
            console.log(params)
            this.$api.getBatchList(params).then(res => {
                this.tableLoading = false;
                if (res && res.code === 0) {
                    this.paginationInfo.pageTotal = parseInt(res.data.total)
                    this.paginationInfo.pageIndex = res.data.pageNum
                    this.tableData = res.data.list
                } else {
                    this.tableLoading = false;
                    this.$message.error(res.errorMsg)
                }
            }).catch(error => {
                console.log(error)
                this.tableLoading = false;
            })
        },
        searchList(e) {
            console.log(e)
            this.info = e
            this.getBatchList(1, this.paginationInfo.pageSize, e)
        },
        // 上下分页
        handleCurrentChange(val) {
            // console.log('上下分页',val)
            let that = this
            this.paginationInfo.pageIndex = val
            this.getBatchList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        // 每页显示多少条
        handleSizeChange(val) {
            // console.log('每页显示多少条',val)
            let that = this
            this.paginationInfo.pageSize = val
            this.getBatchList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        submitDialog(e) {
            this.registerId = e.registerId
            this.centerDialogVisible = true
        },
        onSubmit() {
            let that = this
            console.log(this.pcData)
            if (this.pcData.pcDate.length === 0) {
                this.$message.warning('请选择日期范围')
                return
            }
            if (this.pcData.quantity.toString().trim() == "") {
                this.$message.warning('请输入生产数量')
                return
            }
            if (this.pcData.caliber.toString().trim() == "") {
                this.$message.warning('请输入设备口径')
                return
            }
            let params = {
                startTime: this.pcData.pcDate[0],
                endTime: this.pcData.pcDate[1],
                caliber: this.pcData.caliber,
                multiplier: this.pcData.multiplier,
                quantity: this.pcData.quantity,
                enprNo: this.pcData.enprNo,
                remark: this.pcData.remark,
                tapType: this.pcData.tapType
            }
            this.pcSubmitLoading = true
            if (this.editState) {
                params.id = this.pcData.id
                this.$api.updateBatch(params).then(res => {
                    this.pcSubmitLoading = false
                    console.log(res)
                    if (res.code === 0) {
                        this.$message({
                            message: '更新成功',
                            type: 'success'
                        });
                        this.showDialog = false
                        this.pcData = {
                            id: '',
                            batchNo: '',
                            pcDate: [],
                            quantity: '',
                            multiplier: 1,
                            caliber: '',
                            remark: ''
                        }
                        this.editState = false
                        this.getBatchList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else {
                params.batchNo = this.pcData.batchNo
                this.$api.addBatch(params).then(res => {
                    this.pcSubmitLoading = false
                    console.log(res)
                    if (res.code === 0) {
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });
                        this.showDialog = false
                        this.pcData = {
                            id: '',
                            batchNo: '',
                            pcDate: [],
                            quantity: '',
                            multiplier: 1,
                            caliber: '',
                            remark: '',
                        }
                        this.editState = false
                        this.getBatchList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        updateEnprSumit() {
            let that = this
            this.enprLoading = true
            this.$api.setEnterpriseId({
                batchNo: this.selectedRow.batchNo,
                enterpriseId: this.newEnpr
            }).then(res => {
                this.enprLoading = false
                if (res.code === 0) {
                    this.modifyEnprVisible = false
                    this.getBatchList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                    this.$message({
                        type: 'success',
                        message: `设置完成,${res.msg}条设备`
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: '设置失败'
                    });
                }
            })
        },
        synchronizationSumit() {
            this.syncLoading = true
            this.$api.batchSynchronization({
                batchNo: this.selectedRow.batchNo
            }).then(res => {
                this.syncLoading = false
                if (res.code === 0) {
                    this.synchronizationVisible = false
                    this.$message({
                        type: 'success',
                        message: '批量同步成功'
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: '批量同步失败'
                    });
                }
            })
        },
        handleUpdate(e) {
            this.showDialog = true
            console.log(e)
            this.pcData = {
                id: e.id,
                batchNo: e.batchNo,
                pcDate: [e.startTime, e.endTime],
                quantity: e.quantity,
                multiplier: e.multiplier,
                caliber: e.caliber,
                remark: e.remark,
                enprNo: e.enprNo,
                tapType: e.tapType
            }
            this.editState = true
        },
        handleSetEnpr(e) {
            this.selectedRow = e
            this.modifyEnprVisible = true
        },
        handleSynchronization(e) {
            this.selectedRow = e
            this.synchronizationVisible = true
        },
        addPC() {
            this.pcData = {
                batchNo: this.$Fn.RandomNumber(),
                pcDate: [],
                quantity: '',
                multiplier: 1,
                caliber: '',
                remark: '',
                tapType: 1
            }
            this.showDialog = true
        },
        close() {
            this.showDialog = false
        },
        del(e) {
            let that = this
            this.$confirm('确定要删除该批次编号吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.delBatch({
                    id: e.id
                }).then(res => {
                    if (res.code === 0) {
                        this.getBatchList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: '删除失败'
                        });
                    }
                })
            }).catch(() => {});
        },
        generatePCNo() {
            this.pcData.batchNo = this.$Fn.RandomNumber()
        }
    },
    components: {
        Pagination,
        SearchItem
    },
    filters: {
        icCardFilters(val) {
            if (val) {
                if (val.length > 10) {
                    return val.substring(0, 6) + "****" + val.substring(val.length - 4)
                } else {
                    return val
                }
            } else {
                return val
            }
        }
    }
}
</script>

<style>
/* 标题 */
.menu .title1 img {
    top: 22px;
}

.menu .title1 h3 {
    font-size: 20px;
    margin-top: -14px;
}

/* 分页 */
.el-pagination button,
.el-pagination span:not([class*=suffix]) {
    padding: 0 10px;
}

.home .block-title {
    width: 100%;
    padding: 0 0 28px 20px;
    display: flex;
    align-items: center;
}

.home .block-title h3 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .a-text {
    text-decoration: underline;
    color: #1e61ce;
}

.home .block-title .btn {
    width: 180px;
    height: 35px;
    background-color: #1e61ce;
    border-radius: 20px;
    margin-left: 20px;
    font-weight: normal;
    font-stretch: normal;
    color: #ffffff;
    padding: 0;
}

.home .btn span {
    font-size: 16px !important;
    color: #fff !important;
}

.home .block-title span {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #63717A;
}

.home .top {
    width: 100%;
    padding: 0 0 10px 0;
    border-radius: 10px;
    background: #fefefe;
}

.home .top .title {
    width: 100%;
    height: 90px;
    line-height: 90px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Semibold;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
    border-bottom: 1px solid #f2f3f5;
}

.home .el-col {
    border-radius: 10px;
}

.home .grid-content {
    border-radius: 10px;
    /* min-height: 36px; */
}

.home .leftInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.home .leftInfo .headPic {
    width: 165px;
    height: 165px;
    border-radius: 100%;
    border: 8px solid #fff;
    position: relative;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0px 12px 30px 0px rgba(6, 37, 117, 0.12);
}

.home .leftInfo .headPic img {
    width: 100%;

}

.home .rightInfo {
    margin-top: 10px;
    padding-right: 10px;
}

.home .rightInfo .name-text {

    margin-bottom: 20px;
    font-family: PingFangSC-Semibold;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2d3039;
}

.home .rightInfo .info-text img {
    width: 26px;
    height: 26px;
    margin-right: 20px;
}

.home .rightInfo .info-text {
    margin-bottom: 20px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    letter-spacing: 0px;
    color: #63717a;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-align: left
}

.home .rightInfo .info-text h1 {
    font-size: 20px;
    font-weight: 600;
    font-family: PingFangSC-Medium;
    color: #2d3039;
    margin-left: 10px;
    display: block;
    font-size: 90.5%;
}

.home .middle {
    width: 100%;
    border-radius: 10px;
}

.home .middle .grid-content {
    background: #fff
}

.home .title1 {
    width: 100%;
    display: flex;
    padding: 0 20px 20px 20px;
    align-items: center;
    margin-top: -10px;
    border-bottom: 1px solid #f2f3f5;
}

.home .title1 h3 {
    font-size: 20px;
    margin-top: -10px;
}

.home .title1 img {
    position: relative;
    top: 18px;
    margin-right: 10px;
}

.home .ajs-Title {
    margin-left: 20px;
}

.home .ajs {
    margin-left: 20px;
    color: #0033cf;
}

.home .left-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    /* border-right: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8; */
}

.home .right-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.home .main-block {
    height: 200px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

/*案件办理天数 标签统计 hmm 090403*/
.home .labelModule {
    background: #F2F5FA;
    border: 1px solid #D6E0F0;
    border-radius: 10px;
    display: inline-block;
    width: 100%;
    padding: 20px;
}

.home .labelModule:first-child {
    margin-bottom: 20px;
}

.home .labelModule ul li {
    width: 100%;
    font-size: 16px;
    color: #63717A;
    text-align: center
}

.home .labelModule ul li:first-child {
    margin-bottom: 10px;
}

.home .labelModule ul li font {
    font-size: 41px;
    color: #2D3039;
}

/*公共部分 hmm*/
.p20 {
    padding: 20px;
}

.pb30 {
    padding-bottom: 30px;
}

/*办案类型 数据超出 显示样式 hmm 090404*/

.widthBig .labelModule {
    width: 49.2%;
}

.widthBig .labelModule:first-child {
    margin-bottom: 30px;
    margin-right: 20px;
}

.widthSmall {
    float: right;
}

.rs-block {
    width: 98%;
    height: 80px;
    text-align: right;
    line-height: 80px;
}
</style>
